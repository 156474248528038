import React from 'react';

import PropTypes from 'prop-types';

import { Card, PolycardContext } from '@polycard/card';

const PolycardGrid = ({ item, device, metrics }) => {
  const { polycard_context: polycardContext } = item?.result;
  const contextValue = {
    ...polycardContext,
    type: device === 'desktop' ? 'grid-card' : 'grid',
  };
  const trackingId = metrics?.tracking_id;
  const namespace = 'ui-ms-polycard-container-list';

  const replaceFragment = (fragment) => {
    const decodedFragment = decodeURIComponent(fragment);
    const newFragment = decodedFragment.replace(
      'tracking_id={tracking_id}',
      `tracking_id=${trackingId}`,
    );
    return newFragment;
  };

  return (
    item?.result?.polycards && (
      <div className={`${namespace}`}>
        <PolycardContext
          contextValue={contextValue}
          deviceType={device}
          thousandSeparator={polycardContext.thousand_separator}
          decimalSeparator={polycardContext.decimal_separator}
        >
          {item?.result?.polycards?.map((polycard) => {
            const polycardEnhanced = {
              ...polycard,
              metadata: {
                ...polycard.metadata,
                url_fragments: replaceFragment(polycard.metadata.url_fragments),
              },
            };
            return <Card key={polycard.metadata.id} polycard={polycardEnhanced} />;
          })}
        </PolycardContext>
      </div>
    )
  );
};

PolycardGrid.propTypes = {
  item: PropTypes.shape({
    result: PropTypes.shape({
      polycard_context: PropTypes.shape({
        contract_version: PropTypes.string,
        picture_template: PropTypes.string,
        picture_size_default: PropTypes.string,
        url_prefix: PropTypes.string,
        type: PropTypes.string,
        picture_square_default: PropTypes.string,
        url_fragments_default: PropTypes.string,
        url_params_default: PropTypes.string,
      }),
      polycards: PropTypes.arrayOf(
        PropTypes.shape({
          metadata: PropTypes.shape({}),
          pictures: PropTypes.shape({}),
          components: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      ),
    }),
  }).isRequired,
  device: PropTypes.string.isRequired,
  metrics: PropTypes.shape({
    tracking_id: PropTypes.string,
    matt_tool: PropTypes.string,
  }).isRequired,
};

export default PolycardGrid;
